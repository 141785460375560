import React,{useState,useEffect} from 'react';
import Header from  '../mini-components/Header';
import Footer from  '../mini-components/Footer';
import Userprofile from  '../mini-components/Userprofile';
import { NavLink ,useHistory} from 'react-router-dom';

const Dashboard = () => {

    return (
        <div>
            <Header 
               linkOne={<li><NavLink to="/logout">Logout</NavLink></li>}
               linkTwo={<li><NavLink to="/user-profile">Profile</NavLink></li>}
               linkThree={<li><NavLink to="/project">project</NavLink></li>}
               linkFour={<li><NavLink to="/dashboard">Dashboard</NavLink></li>}
             />
            <Userprofile />
            <Footer/>
        </div>
    )
}

export default Dashboard
