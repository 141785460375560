import React,{useState,useEffect} from 'react';
import { NavLink,useHistory } from 'react-router-dom';
import ProImg from '../../images/profile-img.jpg';
import Car from '../../images/project/car.jpg';
import Chemical from '../../images/project/chemical.jpg';
import Food from '../../images/project/food.jpg';
import Apparel from '../../images/project/apparel.jpg';
import Singleproject from '../mini-components/Singleproject'

const Userprofiledashboard = () => {
  const url = '/api/dashboard'
  const history =  useHistory();
  const [img ,setImg] = useState({});
  const [NIDfront ,setNIDfront] = useState({});
  const [NIDback ,setNIDback] = useState({});
  const [ personalInfo, setpersonalInfo ] = useState({});
  const [ presentAddress, setpresentAddress ] = useState({});
  const [ permanantAddress, setpermanantAddress ] = useState({});

  
  const [isPending, setIspending] =  useState(true);
  useEffect(() => {
   const abortCont =  new AbortController()
    setTimeout(()=>{
        fetch(url,{signal:abortCont.signal})
        .then(res=>{
            if(!res.status === 200){
               const error = new Error(res.error);
               throw error;
            }
            if(res.status === 401){
              history.push('/signin')
            }
            return res.json();
        })
        .then(resdata =>{
            const data = resdata;
            
            const PESONAL_INFO = data[0];
            const PESONAL_INFO_OBJ = PESONAL_INFO[0]

            const PRESENT_ADDRESS = data[1];
            const PRESENT_ADDRESS_OBJ = PRESENT_ADDRESS[0];

            const PERMANANT_ADDRESS = data[2];
            const PERMANANT_ADDRESS_OBJ = PERMANANT_ADDRESS[0];

           const photodata = PESONAL_INFO_OBJ.userPhoto;
           const photodataNIDF = PESONAL_INFO_OBJ.NIDfront;
           const photodataNIDB = PESONAL_INFO_OBJ.NIDback;
           setImg(photodata)
           setNIDfront(photodataNIDF)
           setNIDback(photodataNIDB)
           setpersonalInfo(PESONAL_INFO_OBJ);
           setpresentAddress(PRESENT_ADDRESS_OBJ);
           setpermanantAddress(PERMANANT_ADDRESS_OBJ);
           setIspending(false)
        })
        .catch(err=>{
            if(err.name === 'AbortError'){
                console.log("fetch aborted!!")
            }
            else{
               setIspending(false);
               
            }
             
        })
    },1000);
  

   return () => abortCont.abort();
      
  }, [])

    return (
        <div className="userprofileWrap section-padding">
          <div className="container">
              <h2 className="heading">Profile Info</h2>
              <div className="row p-3 custom-bg">
                  <div className="col-md-8 col-lg-8 col-12 col-sm-6 order-2 order-lg-1 order-md-1">
                      <div className="infoBox-wrap">
                         <table className="table">
                             <tr><th>Name</th><td>{personalInfo.firstName}</td></tr>
                             <tr><th>Profession</th><td>Engineer</td></tr>
                             <tr><th>Address</th><td>{presentAddress.presentAddHouseNo}, {presentAddress.presentAddRoadName},{presentAddress.presentAddDistrict}</td></tr>
                             <tr><th></th><td> </td></tr>
                             <tr><th>Contact No</th><td>{personalInfo.perPhoneOne}</td></tr>
                             <tr><th>Email</th><td></td></tr>
                             <tr><th>Total Invest</th><td>500,000</td></tr>
                            
                         </table>
                      </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-12 col-sm-6 order-lg-0 order-md-2 order-lg-2">
                      <div className="infoBox-right">
                        <h2 className="my-2">Ref ID:{personalInfo.refereeId}</h2>
                        <div className="proImg-wrap">
                            {/* <img src={process.env.PUBLIC_URL + `images/${personalInfo.userPhoto}`}  alt="img"  className="img-fluid" /> */}
                            <img src={`data:image/png;base64,${img}`} alt="img"  width={100}/>
                        </div>
                        <h4>{personalInfo.firstName} {personalInfo.lastName}</h4>

                      </div>
                      <div className="text-right">
                        <NavLink to="personaldataupdate" className="primary-button" style={{fontSize:'10px'}}>Update</NavLink>
                      </div>
                  </div>
              </div>
              <div className="row mt-5">
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Car} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Chemical} title={'002-DEF  Chemicals'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Food} title={'003-GHI  Foods'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Apparel} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
              </div>
          </div>
        </div>
    )
}

export default Userprofiledashboard
