import React,{useEffect, useState} from 'react';
import Header from  '../mini-components/Header';
import Singleinput from '../mini-components/Singleinput'
import Footer from  '../mini-components/Footer';
// import Verify from '../mini-components/Verify';
import {useHistory,NavLink} from 'react-router-dom';

const Codeverify = () => {
    const url = window.location.href;
    const [loader, setLoader] = useState(false)
    const [msg, setMsg] = useState();
    const history =  useHistory();
    const [ userData, setUserData ] = useState([]);

    //sending data to server
    const [user, setUser] = useState({
        veriCode:'',
        perPhoneOne:''
    });

    // get data from the input field
    let name,value;
    const handleInputs =(e)=>{
     name = e.target.name;
     value = e.target.value;
     setUser({...user,[name]:value})

    }
     // sending data to server
     const PostData = async(e)=>{
        e.preventDefault();
        setLoader(true);
        const {veriCode,perPhoneOne} = user;
        if(!veriCode || !perPhoneOne){
             setMsg(<div class="alert alert-danger" role="alert"><strong>Field must not be empty!!</strong></div>)
            // window.alert("Field must be filled with data!!")
        } 
        else{
            const res =  await fetch('/codeverify',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                   veriCode,perPhoneOne
                })
       
            });
            const data = res.json();
            if(res.status === 401 || !data){
                setMsg(<div class="alert alert-danger" role="alert"><strong>Invalid Code!!</strong></div>)
                history.push('/codeverify')
            //  window.alert('Invalid Code!!');
             }
            if(res.status === 500){
                setMsg(<div class="alert alert-danger" role="alert"><strong>No Data found with this number!!</strong></div>)
                history.push('/codeverify')
                //  window.alert('No Data found with this number!!');
             }
             else if(res.status=== 200){
                 window.alert('Registration Successfull');
                 history.push('/signin')
             }
        }
  
      }


      const loaderDis = setTimeout(() => {
        setLoader(false);
      }, 3000);



    return (
        <div>
             <Header
               linkOne={<li><NavLink to="/">Home</NavLink></li> }
               linkTwo={<li><NavLink to="/signup">Register</NavLink></li> }
               linkThree={<li><NavLink to="/signin">Login</NavLink></li> }
            />
            <div className="form-wrapper section-padding">
               <div className="container">
                   <div className="row">
                       <div className="col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                           {msg}
                         <h2 className="text-center">Verify</h2>
                         <form>
                         <div className="card">
                            <div className="card-body">
                               <Singleinput 
                                    labelName={'Phone'} 
                                    col={'col-md-12 col-lg-12 col-12 '} 
                                    type={'text'}
                                    placeholder={'Enter your phone'}
                                    name={'perPhoneOne'}
                                    mandetory={'mandetory'}
                                    value={user.value}
                                    func = {handleInputs}
                                />
                                <Singleinput 
                                    labelName={'Verification Code'} 
                                    col={'col-md-12 col-lg-12 col-12 '} 
                                    type={'text'}
                                    placeholder={'Enter your code'}
                                    name={'veriCode'}
                                    mandetory={'mandetory'}
                                    value={user.value}
                                    func = {handleInputs}
                                />
                                
                                <div className="form-group text-right">
                                <button onClick={PostData}  type="submit"  className="primary-button mt-3 border-0"> {loader ?  <span className="px-4"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span> : 'Verify'}  </button>
                                </div> 
                            </div>
                            </div>
                         </form>
                         <span className="d-block text-danger border p-2"><strong>Please, Submit your code within next 24 hours.Any verification problem? communicate with your reference.</strong></span>
                       </div>
                   </div>
               </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Codeverify;
