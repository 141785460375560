import React,{useState,useEffect} from 'react';
import { NavLink,useHistory } from 'react-router-dom';
import ProImg from '../../images/profile-img.jpg';
import Car from '../../images/project/car.jpg';
import Chemical from '../../images/project/chemical.jpg';
import Food from '../../images/project/food.jpg';
import Apparel from '../../images/project/apparel.jpg';
import Singleproject from './Singleproject'

const Userprofile = () => {
  const history =  useHistory();
  const [ adminData, setAdminData ] = useState({});
  // const [ presentAddress, setpresentAddress ] = useState({});
  
    const callAboutPage = async()=>{
        try {
        const res = await  fetch('/api/admin',{
            method:'GET',
            headers:{
                Accept:'application/json',
                'Content-Type':'application/json'
            },
        });

        let data = await res.json();
        console.log(data)
          data = data[0]
        setAdminData(data);
        if(!res.status === 200 ){
            const error = new Error(res.error);
            throw error;
        }
        if(res.status === 401){
          history.push('/admin-login')
        }
            
        } catch (error) {
            console.log(error)
            history.push('/admin-login')
        }
    }


   useEffect(() => {
    //first load when this page is loaded
      callAboutPage()
       
   }, [])

    return (
        <div className="userprofileWrap section-padding">
          <div className="container">
              <h2 className="heading">Profile Info</h2>
              <div className="row p-3 custom-bg">
                  <div className="col-md-8 col-lg-8 col-12 col-sm-6 order-2 order-lg-1 order-md-1">
                      <div className="infoBox-wrap">
                         <table className="table">
                             <tr><th>Name</th><td>{adminData.firstName}</td></tr>
                             <tr><th>Profession</th><td>Engineer</td></tr>
                             <tr><th>Address</th><td>{}</td></tr>
                             <tr><th></th><td>148 Green Road,Dhaka</td></tr>
                             <tr><th>Contact No</th><td>{adminData.phone}</td></tr>
                             <tr><th>Email</th><td>{adminData.email}</td></tr>
                             <tr><th>Total Invest</th><td>500,000</td></tr>
                            
                         </table>
                      </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-12 col-sm-6 order-lg-0 order-md-2 order-lg-2">
                      <div className="infoBox-right">
                        <h2 className="my-2">ID: CC06210010001</h2>
                        <div className="proImg-wrap">
                            <img src={`data:image/png;base64, ${(adminData.adminPhoto)}`} alt="img"  width={150}/>
                            {/* <img src={ProImg} alt="proimg" className="img-fluid" /> */}
                        </div>
                        <h4>{adminData.firstName} {adminData.lastName}</h4>

                      </div>
                      <div className="text-right">
                        <NavLink to="updateinfo" className="primary-button" style={{fontSize:'10px'}}>Update</NavLink>
                      </div>
                  </div>
              </div>
              <div className="row mt-5">
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Car} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Chemical} title={'002-DEF  Chemicals'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Food} title={'003-GHI  Foods'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-6 col-12">
                    <Singleproject img={Apparel} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                  </div>
              </div>
          </div>
        </div>
    )
}

export default Userprofile
