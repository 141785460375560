import React,{useState} from 'react';
import Header from  '../mini-components/Header';
import Singleinput from '../mini-components/Singleinput'
import Footer from  '../mini-components/Footer';
import { NavLink,useHistory } from 'react-router-dom';
const Signin = () => {

    const [loader, setLoader] = useState(false);
    const [msg, setMsg] = useState();
    const history = useHistory()
    const [user, setUser] = useState({
        perPhoneOne:'',
        password:'',
    });

    // get data from the input field
    let name,value;
    const handleInputs =(e)=>{
     name = e.target.name;
     value = e.target.value;
     setUser({...user,[name]:value})

    }
     // sending data to server
     const PostData = async(e)=>{
        e.preventDefault();
        setLoader(true);
        const { 
            perPhoneOne,
            password
        } = user;
        if(!perPhoneOne || !password){
            setMsg(<div class="alert alert-danger" role="alert"><strong>Field must not be empty!!</strong></div>)
            // window.alert("Field must not be empty!!")
        }else{
            const res =  await fetch('/signin',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                     perPhoneOne,
                     password
                 })  
            });
           
           const data = res.json();
           console.log(res);
           if(res.status === 422 || !data){
            setMsg(<div class="alert alert-danger" role="alert"><strong>Invalid Login!!</strong></div>)
            //    window.alert('Invalid Login!!');
               history.push('/signin')
           }else if(res.status === 400){
            setMsg(<div class="alert alert-danger" role="alert"><strong>Primary Security Code Verification Required!!</strong></div>)
            // window.alert('Primary Security Code Verification Required!');
            history.push('/codeverify')
           }
           else if(res.status === 401){
            setMsg(<div class="alert alert-danger" role="alert"><strong>Admin Verification Required!!</strong></div>)
            // window.alert('Admin Verification Required!!');
            history.push('/signin')  
           }
           else if(res.status === 404){
               setMsg(<div class="alert alert-danger" role="alert"><strong>User dosen't exitst!!</strong></div>)
            // window.alert('User dosent exist!!');
            history.push('/signin')  
           }
           else if( res.status === 200){
            window.alert('Successfully Login');
            history.push('/dashboard')
           }
           
        }
       
  
      }

      const loaderDis = setTimeout(() => {
        setLoader(false);
      }, 3000);
   
    return (
        <div>
            <Header
               linkOne = {<li><NavLink to="/signup">Register</NavLink></li> }
               linkTwo = {<li><NavLink to="/signin">Login</NavLink></li> }
               linkThree = {<li><NavLink to="/codeverify">Verification</NavLink></li> }
            />
            <div className="form-wrapper section-padding">
               <div className="container">
                   <div className="row">
                       <div className="col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                        {/* msg-box */}
                          {msg}
                         <h2 className="text-center">Signin</h2>
                         <form method="POST">
                               <div className="single-box-wrap mb-5">
                                <div className="form-row">
                                    <Singleinput 
                                        labelName={'Phone'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'text'}
                                        placeholder={'Enter Phone'}
                                        name={'perPhoneOne'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                      <Singleinput 
                                        labelName={'Password'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'password'}
                                        placeholder={'Enter password'}
                                        name={'password'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                  </div>
                               </div>
                            <div className="form-group text-right">
                            <button onClick={PostData}  type="submit"  className="primary-button mt-3 border-0"> {loader ?  <span className="px-4"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span> : 'Sign In'}  </button>
                            </div>
                            </form>
                       </div>
                   </div>
               </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Signin
