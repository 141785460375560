import React,{useState} from 'react';
import Header from  '../mini-components/Header';
import Singleinput from '../mini-components/Singleinput'
import Footer from  '../mini-components/Footer';
import { NavLink,useHistory } from 'react-router-dom';
const Adminlogin = () => {
    const history = useHistory()
    const [msg, setMsg] = useState();
    const [user, setUser] = useState({
        phone:'',
        password:'',
    });

    // get data from the input field
    let name,value;
    const handleInputs =(e)=>{
     name = e.target.name;
     value = e.target.value;
     setUser({...user,[name]:value})

    }
     // sending data to server
     const PostData = async(e)=>{
        e.preventDefault();
        const { 
            phone,
            password
        } = user;
        if(!phone || !password){
            setMsg(<div class="alert alert-danger" role="alert"><strong>Field must not be empty!!</strong></div>)
            // window.alert("Field must not be empty!!")
        }else{
            const res =  await fetch('admin-login',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                     phone,
                     password
                 })  
            });
           const data = res.json();
           if(res.status === 422 || !data || res.status === 401 || res.status === 500){
            setMsg(<div class="alert alert-danger" role="alert"><strong>Invalid Login!!</strong></div>)
            //    window.alert('Invalid Login!!');
            //    history.push('/admin-login')
           }
           if(res.status === 200){
             history.push('/admin')
           }
        //    else{
        //     setMsg(<div class="alert alert-success" role="alert"><strong>Successfully Login!!</strong></div>)
        //     //    window.alert('Successfully Login');
        //     //    history.push('/admin')
        //    }

        }
       
  
      }
   

    return (
        <div>
            <Header
            //    linkOne = {<li><NavLink to="/create-admin">Create Admin</NavLink></li> }
            //    linkTwo = {<li><NavLink to="/signin">Login</NavLink></li> }
            />
            <div className="form-wrapper section-padding">
               <div className="container">
                   <div className="row">
                       <div className="col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                           {msg}
                         <h2 className="text-center">Admin Signin</h2>
                         <form method="POST">
                               <div className="single-box-wrap mb-5">
                                <div className="form-row">
                                    <Singleinput 
                                        labelName={'Phone'}
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'text'}
                                        placeholder={'Enter Email'}
                                        name={'phone'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                    <Singleinput 
                                        labelName={'Password'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'password'}
                                        placeholder={'Enter password'}
                                        name={'password'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                  </div>
                               </div>
                            <div className="form-group text-right">
                             <button onClick={PostData} type="submit" className="primary-button mt-1 border-0">Sign in</button>
                            </div>
                            </form>
                       </div>
                   </div>
               </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Adminlogin
