import React,{useState,useEffect} from 'react';
import Header from  '../mini-components/Header';
import Footer from  '../mini-components/Footer';
import Userprofile from  '../mini-components/Userprofile';
import { NavLink ,useHistory} from 'react-router-dom';

const Userprfile = () => {
    const url = '/api/user-profile'
    const history =  useHistory();
    const [img ,setImg] = useState({});
    const [NIDfront ,setNIDfront] = useState({});
    const [nomineeOnePhoto ,setNomineeimg] = useState();
    const [nomineeTwoPhoto ,setNomineetwoimg] = useState({});
    const [NIDback ,setNIDback] = useState({});
    const [ personalInfo, setpersonalInfo ] = useState({});
    const [ presentAddress, setpresentAddress ] = useState({});
    const [ permanantAddress, setpermanantAddress ] = useState({});
    const [ nomineeOne, setnomineeOne ] = useState({});
    const [ nomineeTwo, setnomineeTwo ] = useState({});
  
    
    const [isPending, setIspending] =  useState(true);
    useEffect(() => {
     const abortCont =  new AbortController()
      setTimeout(()=>{
          fetch(url,{signal:abortCont.signal})
          .then(res=>{
              if(!res.status === 200){
                 const error = new Error(res.error);
                 throw error;
              }
              return res.json();
          })
          .then(resdata =>{
              const data = resdata;
            //   console.log("dashboard data:",resdata)
         
              const PESONAL_INFO = data[0];
              setpersonalInfo(PESONAL_INFO[0]);
              const PESONAL_INFO_OBJ = PESONAL_INFO[0];

            //   console.log("personal data:",PESONAL_INFO_OBJ)
          
  
              const PRESENT_ADDRESS = data[1];
              setpresentAddress(PRESENT_ADDRESS[0]);
              const PERMANANT_ADDRESS = data[2];
              setpermanantAddress(PERMANANT_ADDRESS[0]);
              

              const NOMINEE_ONE = data[3];
              setnomineeOne(NOMINEE_ONE[0]);

              const NOMINEE_TWO = data[4];

              const NOMINEE_TWO_OBJ =NOMINEE_TWO[0];
  
            //  const photodata = PESONAL_INFO[0].userPhoto;
            //  const photodataNIDF = PESONAL_INFO_OBJ.NIDfront;
            //  const photodataNIDB = PESONAL_INFO_OBJ.NIDback;
            //  const nomineeOnePhoto = NOMINEE_ONE_OBJ.nomineePhoto;
            //  const nomineeTwoPhoto = NOMINEE_TWO_OBJ.nomineePhoto;
            //  setNomineeimg(nomineeOnePhoto)
            //  setNomineetwoimg(nomineeTwoPhoto)
            //  setImg(photodata)
            //  setNIDfront(photodataNIDF)
            //  setNIDback(photodataNIDB)
             setnomineeTwo(NOMINEE_TWO[0]);
             setIspending(false)
          })
          .catch(err=>{
              if(err.name === 'AbortError'){
                  console.log("fetch aborted!!")
              }
              else{
                 setIspending(false); 
              }
               
          })
      },1000);
    
  
     return () => abortCont.abort();
        
    }, [])
    return (
        <div>
            <Header 
               linkOne={<li><NavLink to="/logout">Logout</NavLink></li> }
               linkTwo={<li><NavLink to="/user-profile">Profile</NavLink></li>}
               linkThree={<li><NavLink to="/project">project</NavLink></li>}
               linkFour={<li><NavLink to="/dashboard">Dashboard</NavLink></li>}
               linkFive={<li><NavLink to={`/addnominee?key=${personalInfo.perPhoneOne}`}>Add New Nominee</NavLink></li>}
             />
             <div className="userprofileWrap section-padding">
                <div className="container">
                    <h2 className="heading">Personal Information</h2>
                    <div className="row p-3 custom-bg">
                        <div className="col-md-12 col-lg-12 col-12 col-sm-6 order-2 order-lg-1 order-md-1">
                            <div className="infoBox-wrap d-flex justify-content-between w-100">
                                <table className="table">
                                    <tr><th>First Name:</th><td>{personalInfo.firstName}{console.log("inside dom",personalInfo)}</td></tr>
                                    <tr><th>last Name:</th><td>{personalInfo.lastName}</td></tr>
                                    <tr><th>Email:</th><td>{personalInfo.email}</td></tr>
                                    <tr><th>Personal Number-1:</th><td>{personalInfo.perPhoneOne}</td></tr>
                                    <tr><th>Personal Number-2:</th><td>{personalInfo.perPhoneTwo}</td></tr>
                                    <tr><th>User Photo</th><td><img src={`data:image/png;base64,${personalInfo.userPhoto}`} alt="img"  width={150}/></td></tr>
                                    <tr><th>NID FRONT</th><td><img src={`data:image/png;base64,${personalInfo.NIDfront}`} alt="img"  width={150}/></td></tr>
                                   
                                </table>
                                <table className="table">
                                    <tr><th>Personal Number-3:</th><td>{personalInfo.perPhoneThree}</td></tr>
                                    <tr><th>Date of Birth</th><td>{personalInfo.dateOfBirth}</td></tr>
                                    <tr><th>Reference Id:</th><td>{personalInfo.refereeId}</td></tr>
                                    <tr><th>Nearest landMark</th><td></td></tr>
                                    <tr><th>NID BACK</th><td><img src={`data:image/png;base64,${personalInfo.NIDback}`} alt="img"  width={150}/></td></tr>
                                   
                                </table>
                            </div>
                            <div className="text-right">
                                <NavLink to="presentdataupdate" className="primary-button" style={{fontSize:'10px'}}>Update</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="userprofileWrap section-padding">
                <div className="container">
                    <h2 className="heading">Present Address</h2>
                    <div className="row p-3 custom-bg">
                        <div className="col-md-12 col-lg-12 col-12 col-sm-6 order-2 order-lg-1 order-md-1">
                            <div className="infoBox-wrap d-flex justify-content-between w-100">
                                <table className="table">
                                    <tr><th>House No:</th><td>{presentAddress.presentAddHouseNo}</td></tr>
                                    <tr><th>House Name:</th><td>{presentAddress.presentAddHouseName}</td></tr>
                                    <tr><th>Lane Number:</th><td>{presentAddress.presentLaneNumber}</td></tr>
                                    <tr><th>Road No:</th><td>{presentAddress.presentAddRoadNo}</td></tr>
                                </table>
                                <table className="table">
                                    
                                    <tr><th>Police Station</th><td>{presentAddress.presentAddPoliceStn}</td></tr>
                                    <tr><th>Upozella</th><td>{presentAddress.presentAddUpozella}</td></tr>
                                    <tr><th>PostCode</th><td>{presentAddress.presentAddPostCode}</td></tr>
                                    <tr><th>Nearest landMark</th><td></td></tr>
                                </table>
                            </div>
                            <div className="text-right">
                                <NavLink to="presentdataupdate" className="primary-button" style={{fontSize:'10px'}}>Update</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="userprofileWrap section-padding">
                <div className="container">
                    <h2 className="heading">Permanant Address</h2>
                    <div className="row p-3 custom-bg">
                        <div className="col-md-12 col-lg-12 col-12 col-sm-6 order-2 order-lg-1 order-md-1">
                            <div className="infoBox-wrap d-flex justify-content-between w-100">
                                <table className="table">
                                    <tr><th>House No:</th><td>{permanantAddress.presentAddHouseNo}</td></tr>
                                    <tr><th>House Name:</th><td>{permanantAddress.presentAddHouseName}</td></tr>
                                    <tr><th>Lane Number:</th><td>{permanantAddress.presentLaneNumber}</td></tr>
                                    <tr><th>Road No:</th><td>{permanantAddress.presentAddRoadNo}</td></tr>
                                </table>
                                <table className="table">
                                    
                                    <tr><th>Police Station</th><td>{permanantAddress.presentAddPoliceStn}</td></tr>
                                    <tr><th>Upozella</th><td>{permanantAddress.presentAddUpozella}</td></tr>
                                    <tr><th>PostCode</th><td>{permanantAddress.presentAddPostCode}</td></tr>
                                    <tr><th>Nearest landMark</th><td></td></tr>
                                </table>
                            </div>
                            <div className="text-right">
                                <NavLink to="permanantdataupdate" className="primary-button" style={{fontSize:'10px'}}>Update</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="userprofileWrap section-padding">
                <div className="container">
                    <h2 className="heading">Nominee One</h2>
                    <div className="row p-3 custom-bg">
                        <div className="col-md-12 col-lg-12 col-12 col-sm-6 order-2 order-lg-1 order-md-1">
                            <div className="infoBox-wrap d-flex justify-content-between w-100">
                                <table className="table">
                                    <tr><th>Name:</th><td>{nomineeOne ? nomineeOne.nomineeName:null}{console.log('Nominee Data:',nomineeOne)}</td></tr>
                                    <tr><th>Relationship:</th><td>{nomineeOne ? nomineeOne.nomineeRelationship: null}</td></tr>
                                    <tr><th>Percentage:</th><td>{nomineeOne ? nomineeOne.nomineePercentage: null}</td></tr>
                                    <tr><th>Image:</th><td><img src={`data:image/png;base64,${nomineeOne ? nomineeOne.nomineePhoto: null}`} alt="img"  width={150}/></td></tr>
                                </table>
                                <table className="table">
                                    <tr><th>House Name</th><td>{nomineeOne ? nomineeOne.nomineeAddHouseName:null}</td></tr>
                                    <tr><th>Lane Number</th><td>{nomineeOne ? nomineeOne.nomineeLaneNumber:null}</td></tr>
                                    <tr><th>Road No</th><td>{nomineeOne ? nomineeOne.nomineeAddRoadNo:null}</td></tr>
                                    <tr><th>Police Station</th><td>{nomineeOne ? nomineeOne.nomineeAddPoliceStn:null}</td></tr>
                                </table>
                                <table className="table">
                                    <tr><th>Upozella</th><td>{nomineeOne.nomineeAddUpozella}</td></tr>
                                    <tr><th>Post Code</th><td>{nomineeOne.nomineeAddPostCode}</td></tr>
                                    <tr><th>Phone</th><td>{nomineeOne.nomineePhoneOne}</td></tr>
                                    <tr><th>District</th><td>{nomineeOne.nomineeAddDistrict}</td></tr>
                                </table>
                            </div>
                            <div className="text-right">
                                <NavLink to="nomineedataupdate" className="primary-button" style={{fontSize:'10px'}}>Update</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5">
                    <h2 className="heading">Nominee Two</h2>
                    <div className="row p-3 custom-bg">
                        <div className="col-md-12 col-lg-12 col-12 col-sm-6 order-2 order-lg-1 order-md-1">
                            <div className="infoBox-wrap d-flex justify-content-between w-100">
                                <table className="table">
                                    <tr><th>Name:</th><td>{nomineeTwo ? nomineeTwo.nomineeName : null}</td></tr>
                                    <tr><th>Relationship:</th><td>{nomineeTwo ? nomineeTwo.nomineeRelationship : null}</td></tr>
                                    <tr><th>Percentage:</th><td>{ nomineeTwo ? nomineeTwo.nomineePercentage : null}</td></tr>
                                    <tr><th>Image:</th><td><img src={`data:image/png;base64,${nomineeTwo ? nomineeTwo.nomineePhoto : null}`} alt="img"  width={150}/></td></tr>
                                </table>
                                <table className="table">
                                    <tr><th>House Name</th><td>{nomineeTwo ? nomineeTwo.nomineeAddHouseName : null}</td></tr>
                                    <tr><th>Lane Number</th><td>{nomineeTwo ? nomineeTwo.nomineeLaneNumber: null}</td></tr>
                                    <tr><th>Road No</th><td>{nomineeTwo ? nomineeTwo.nomineeAddRoadNo:null}</td></tr>
                                    <tr><th>Police Station</th><td>{nomineeTwo ? nomineeTwo.nomineeAddPoliceStn:null}</td></tr>
                                </table>
                                <table className="table">
                                    <tr><th>Upozella</th><td>{nomineeTwo ? nomineeTwo.nomineeAddUpozella:null}</td></tr>
                                    <tr><th>Post Code</th><td>{nomineeTwo? nomineeTwo.nomineeAddPostCode:null}</td></tr>
                                    <tr><th>Phone</th><td>{nomineeTwo ? nomineeTwo.nomineePhoneOne:null}</td></tr>
                                    <tr><th>District</th><td>{nomineeTwo ? nomineeTwo.nomineeAddDistrict:null}</td></tr>
                                </table>
                            </div>
                            <div className="text-right">
                                <NavLink to="nomineedataupdate" className="primary-button" style={{fontSize:'10px'}}>Update</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Userprfile
