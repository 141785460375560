import React,{useState,useEffect} from 'react';
import Header from  '../mini-components/Header';
import Form from  '../mini-components/Form';
import Footer from  '../mini-components/Footer';
import Singleinput from '../mini-components/Singleinput';
import { NavLink,useHistory } from 'react-router-dom';
import axios from 'axios';

const Permanantdataupdate = () => {

    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [msg, setMsg] = useState()


  
// axios---------------------------------------------
const [user, setNewUser] = useState(
    {
        firstName:'',
        email:'',
        lastName:'',
        perPhoneOne:'',
        perPhoneTwo:'',
        perPhoneThree:'',
        dateOfBirth:'',
        NIDnumber:'',
        password:'',
        // userPhoto:'',
        // NIDfront:'',
        // NIDback:'',
        
    }
);
// const { firstName, lastName, email, perPhoneOne, perPhoneTwo, perPhoneThree, dateOfBirth, NIDnumber, password } = user;
const handleInputs = (e) => {
    setNewUser({...user, [e.target.name]: e.target.value});
}


const callAboutPage = async()=>{
    try {
    const res = await  fetch('/personaldataupdate',{
        method:'GET',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json'
        },
        credentials:"include"
    });

    const data = await res.json();
    console.log(data)
    setNewUser(data)
    console.log(user)
    if(!res.status === 200 ){
        const error = new Error(res.error);
        throw error;
    }
        
    } catch (error) {
        console.log(error)
        history.push('/signin')
    }
}
useEffect(() => {
    //first load when this page is loaded
      callAboutPage()
       
   }, []) 


//  IMAGE PREVIEW + Upload=========================
const [state, setstate] = useState(null)
const [stateSecond, setstateSecond] = useState(null)
const [stateFourth, setstateFourth] = useState(null)

const imageHandleruserPhoto = (e) => {
    // setNewUser({...user, userPhoto: e.target.files[0]});
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstateFourth(reader.result);
           
        }

    }
    reader.readAsDataURL(e.target.files[0])
}
const imageHandlerNidFront = (e) => {
    // setNewUser({...user, NIDfront: e.target.files[0]});
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstate(reader.result);
           
        }

    }
    reader.readAsDataURL(e.target.files[0])
}

const imageHandlerNidBack = (e) => {
    // setNewUser({...user, NIDback: e.target.files[0]});
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstateSecond(reader.result);
        }
    }
    reader.readAsDataURL(e.target.files[0])
}
   
const loaderDis = setTimeout(() => {
    setLoader(false);
  }, 3000);

    return (
        <div>
            <Header 
               linkOne={<li><NavLink to="/logout">Logout</NavLink></li> }
               linkTwo={<li><NavLink to="/user-profile">Profile</NavLink></li>}
               linkThree={<li><NavLink to="/project">project</NavLink></li>}
               linkFour={<li><NavLink to="/dashboard">Dashboard</NavLink></li>}
             />
             <div>
                
                <div className="form-wrapper section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            {msg}
                        <h2 className="text-center">Update Permanant Address Data</h2>
                        <form  encType='multipart/form-data'>
                        <div class="card">
                                
                                <div class="card-body">
                                <div className="single-box-wrap mb-5">
                                    <h4 className="formheading">Permanant Address</h4>
                                    <div className="form-row">
                                    
                                        <Singleinput 
                                            labelName={'House Number'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter House Number'}
                                            name={'permanantAddHouseNo'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'House Name'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter House Name'}
                                            name={'permanantAddHouseName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                        <Singleinput 
                                            labelName={'Lane Number'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Lane Number'}
                                            name={'permanantAddLaneNumber'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Lane Name'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter Lane Name'}
                                            name={'permanantAddLaneName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Number'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Number'}
                                            name={'permanantAddRoadNo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Name'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Name'}
                                            name={'permanantAddRoadName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Police Station'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddPoliceStn'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Village'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddVillage'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Upozella'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddUpozella'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Post Code'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddPostCode'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'District'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddDistrict'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Nearest Landmark'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddNearestLandmark'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                    </div>
                                </div>
                                </div>
                               
                            </div>
                                {/* submit-button */}
                                <div className="form-group text-right">
                                <button   type="submit"  className="primary-button mt-3 border-0"> {loader ?  <span className="px-4"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span> : 'Update'}  </button>
                              </div> 
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Permanantdataupdate
