import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';
import Singleinput from '../mini-components/Singleinput';
import { FaAngleDown } from "react-icons/fa";
import Verify from './Verify';
import axios from 'axios';

const Form = () => {

    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [msg, setMsg] = useState()

    const [fileOne, setfileOne] = useState(null)
    const [fileTwo, setfileTwo] = useState(null)
    const [fileThree, setfileThree] = useState(null)
    const [fileFour, setfileFour] = useState(null)
   
// axios---------------------------------------------
const [user, setNewUser] = useState(
    {
        referenceNumber:'',
        firstName:'',
        email:'',
        lastName:'',
        perPhoneOne:'',
        perPhoneTwo:'',
        perPhoneThree:'',
        dateOfBirth:'',
        NIDnumber:'',
        password:'',
        presentAddHouseNo:'',
        presentAddHouseName:'',
        presentLaneNumber:'',
        presentAddRoadNo:'',
        presentAddPoliceStn:'',
        presentAddVillage:'',
        presentAddUpozella:'',
        presentAddPostCode:'',
        presentAddDistrict:'',
        permanantAddHouseNo:'',
        permanantAddHouseName:'',
        permanantAddLaneNumber:'',
        permanantAddRoadNo:'',
        permanantAddPoliceStn:'',
        permanantAddVillage:'',
        permanantAddUpozella:'',
        permanantAddPostCode:'',
        permanantAddDistrict:'',
        nomineeAddHouseName:'',
        nomineeLaneNumber:'',
        nomineeAddRoadNo:'',
        nomineePhoneOne:'',
        nomineeAddPoliceStn:'',
        nomineeAddVillage:'',
        nomineeAddUpozella:'',
        nomineeAddPostCode:'',
        nomineeAddDistrict:'',
        // nomineePhoto:'',
        nomineeRelationship:'',
        nomineePercentage:'',
    }
);
const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append('fileOne', fileOne);
    formData.append('fileTwo', fileTwo);
    formData.append('fileThree', fileThree);
    formData.append('dateOfBirth', user.dateOfBirth);
    formData.append('firstName', user.firstName);
    formData.append('email', user.email);
    formData.append('password', user.password);
    formData.append('referenceNumber', user.referenceNumber);
    formData.append('lastName', user.lastName);
    formData.append('perPhoneOne', user.perPhoneOne);
    formData.append('perPhoneTwo', user.perPhoneTwo);
    formData.append('perPhoneThree', user.perPhoneThree);
    formData.append('NIDnumber', user.NIDnumber);
    formData.append('presentAddHouseNo', user.presentAddHouseNo);
    formData.append('presentAddHouseName', user.presentAddHouseName);
    formData.append('presentAddLaneNumber', user.presentAddLaneNumber);
    formData.append('presentAddRoadNo', user.presentAddRoadNo);
    formData.append('presentAddPoliceStn', user.presentAddPoliceStn);
    formData.append('presentAddUpozella', user.presentAddUpozella);
    formData.append('presentAddPostCode', user.presentAddPostCode);
    formData.append('presentAddDistrict', user.presentAddDistrict);
    formData.append('permanantAddHouseNo', user.permanantAddHouseNo);
    formData.append('permanantAddHouseName', user.permanantAddHouseName);
    formData.append('permanantAddLaneNumber', user.permanantAddLaneNumber);
    formData.append('permanantAddRoadNo', user.permanantAddRoadNo);
    formData.append('permanantAddPoliceStn', user.permanantAddPoliceStn);
    formData.append('permanantAddUpozella', user.permanantAddUpozella);
    formData.append('permanantAddPostCode', user.permanantAddPostCode);
    formData.append('permanantAddDistrict', user.permanantAddDistrict);
    formData.append('nomineeAddHouseName', user.nomineeAddHouseName);
    formData.append('nomineeLaneNumber', user.nomineeLaneNumber);
    formData.append('nomineeAddRoadNo', user.nomineeAddRoadNo);
    formData.append('nomineePhoneOne', user.nomineePhoneOne);
    formData.append('nomineeAddPoliceStn', user.nomineeAddPoliceStn);
    formData.append('nomineeAddUpozella', user.nomineeAddUpozella);
    formData.append('nomineeAddPostCode', user.nomineeAddPostCode);
    formData.append('nomineeAddDistrict', user.nomineeAddDistrict);
    formData.append('fileFour',fileFour);
    formData.append('nomineeRelationship', user.nomineeRelationship);
    formData.append('nomineePercentage', user.nomineePercentage);
    formData.append('nomineeName', user.nomineeName);
    // validation----------------------
    if(user.referenceNumber === ""){
        setMsg(<div class="alert alert-danger" role="alert"><strong>RefereeId must not be empty!!</strong></div>)
        history.push('/signup')
        console.log("referee id is empty!!")
    }
    if(!fileOne || !fileTwo || !fileThree || !fileFour){
        setMsg(<div class="alert alert-danger" role="alert"><strong>All Photograph Field Required!!!</strong></div>)
    }
     
  axios.post('/signup', formData)
         .then(res => {
            console.log(res);
            if(res.status === 404){
                setMsg(<div class="alert alert-danger" role="alert"><strong>Invalid RefereeId!!</strong></div>)
            }
            if(res.status === 500){
                setMsg(<div class="alert alert-danger" role="alert"><strong>User already exist!!</strong></div>)
            }
            if(res.status === 422){
                setMsg(<div class="alert alert-danger" role="alert"><strong>Invalid Registration!!</strong></div>)
                // window.alert('Invalid Registration!!');
            }
            else{
                setMsg(<div class="alert alert-success" role="alert"><strong>Successfully Created.</strong></div>)
                // window.alert('code has been sent');
                console.log("formData:",user)
                history.push(`/sendsms/${user.referenceNumber}/${user.perPhoneOne}`)
            }
         })
         .catch(err => {
            console.log(err);
         });
};
const handleInputs = (e) => {
    setNewUser({...user, [e.target.name]: e.target.value});
}

//  IMAGE PREVIEW + Upload=========================
const [state, setstate] = useState(null)
const [stateSecond, setstateSecond] = useState(null)
const [stateThird, setstateThird] = useState(null)
const [stateFourth, setstateFourth] = useState(null)



const imageHandleruserPhoto = (e) => {
    // setNewUser({...user, userPhoto: e.target.files[0]});
    const USER_PHOTO =  e.target.files[0];
    setfileOne(USER_PHOTO);
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstateFourth(reader.result);
           
        }

    }
    reader.readAsDataURL(e.target.files[0])
}
const imageHandlerNidFront = (e) => {
    const NID_FRONT =  e.target.files[0];
    setfileTwo(NID_FRONT);
    // setNewUser({...user, NIDfront: e.target.files[0]});
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstate(reader.result);
           
        }

    }
    reader.readAsDataURL(e.target.files[0])
}

const imageHandlerNidBack = (e) => {
    const NID_BACK =  e.target.files[0];
    setfileThree(NID_BACK);
    // setNewUser({...user, NIDback: e.target.files[0]});
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstateSecond(reader.result);
        }
    }
    reader.readAsDataURL(e.target.files[0])
}
const nomineeIMGhandler = (e) => {
    const NOMINEE_PHOTO =  e.target.files[0];
    setfileFour(NOMINEE_PHOTO);
    // setNewUser({...user, nomineePhoto: e.target.files[0]});
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstateThird(reader.result);
        }
    }
    reader.readAsDataURL(e.target.files[0])
}
   
const loaderDis = setTimeout(() => {
    setLoader(false);
  }, 3000);

    return (
        <div>
            <div className="form-wrapper section-padding">
               <div className="container">
                   <div className="row">
                       <div className="col-md-12 col-lg-12">
                           {msg}
                         <h2 className="text-center">Create Account</h2>
                        <form onSubmit={handleSubmit}>
                         <div className="accordion custom-accordion" id="accordionExample" >
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                <h4 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                      <span>Personal Information</span><span className="float-right"><FaAngleDown/></span>
                                    </button>
                                </h4>
                                </div>

                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
                                <div className="single-box-wrap mb-5">
                                    <h4 className="formheading">Personal Information</h4>
                                    <div className="form-row">
                                        <Singleinput 
                                            labelName={'Reference Number'} 
                                            col={'col-md-12 col-lg-12 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter your ref Id'}
                                            name={'referenceNumber'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                         <Singleinput 
                                            labelName={'Email'} 
                                            col={'col-md-12 col-lg-12 col-12 '} 
                                            type={'email'}
                                            name={'email'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'First Name'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter your first name'}
                                            name={'firstName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Last Name'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter your last name'}
                                            name={'lastName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Phone-1'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter Mobile no'}
                                            name={'perPhoneOne'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Phone-2'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter mobile no'}
                                            name={'perPhoneTwo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Phone-3'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter mobile no'}
                                            name={'perPhoneThree'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Date of Birth'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'date'}
                                            name={'dateOfBirth'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'NID Number'} 
                                            col={'col-md-12 col-lg-12 col-12 '} 
                                            type={'text'}
                                            name={'NIDnumber'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                    
                                        <div className={`form-group col-md-4 col-lg-4 col-12 `}>
                                            <label for="refId"className="mandetory">User Photo</label>
                                            <input type="file" className="form-control" id="inputEmail4" placeholder="" name="userPhoto" accept="image/*" onChange={imageHandleruserPhoto}/>
                                            <div className={`image-upload-box w-100` } style={{backgroundImage:`url(${stateFourth})`,backgroundSize:'cover',backgroundPosition:"center"}}></div>
                                        </div>
                                        <div className={`form-group col-md-4 col-lg-4 col-12 `}>
                                            <label for="refId"className="mandetory">NID Front</label>
                                            <input type="file" className="form-control" id="inputEmail4" placeholder="" name="NIDfront" accept="image/*" onChange={imageHandlerNidFront}/>
                                            <div className={`image-upload-box w-100` } style={{backgroundImage:`url(${state})`,backgroundSize:'cover',backgroundPosition:"center"}}></div>
                                        </div>
                                        <div className={`form-group col-md-4 col-lg-4 col-12 `}>
                                            <label for="refId"className="mandetory">NID Back</label>
                                            <input type="file" className="form-control" id="inputEmail4" placeholder="" name="NIDback" accept="image/*" onChange={imageHandlerNidBack}/>
                                            <div className={`image-upload-box w-100` } style={{backgroundImage:`url(${stateSecond})`,backgroundSize:'cover',backgroundPosition:"center"}}></div>
                                        </div>
                                        <Singleinput 
                                                labelName={'Password'} 
                                                col={'col-md-12 col-lg-12 col-12 '} 
                                                type={'password'}
                                                name={'password'}
                                                mandetory={'mandetory'}
                                                func={handleInputs}
                                                value={user.name}
                                            />
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                <h4 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span>Present Address</span><span className="float-right"><FaAngleDown/></span>
                                    </button>
                                </h4>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                <div className="single-box-wrap mb-5">
                                    <h4 className="formheading">Present Address</h4>
                                    <div className="form-row">
                                    
                                        <Singleinput 
                                            labelName={'House Number'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter House Number'}
                                            name={'presentAddHouseNo'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'House Name'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter House Name'}
                                            name={'presentAddHouseName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Lane Number'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Lane Number'}
                                            name={'presentAddLaneNumber'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Lane Name'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter Lane Name'}
                                            name={'presentAddLaneName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Number'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Number'}
                                            name={'presentAddRoadNo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Name'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Name'}
                                            name={'presentAddRoadName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Police Station'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'presentAddPoliceStn'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Village'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'presentAddVillage'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Upozella'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'presentAddUpozella'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Post Code'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'presentAddPostCode'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'District'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'presentAddDistrict'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Nearest Landmark'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'presentAddNearestLandmark'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                <h4 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span>Permananet Address</span><span className="float-right"><FaAngleDown/></span>
                                    </button>
                                </h4>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                <div className="single-box-wrap mb-5">
                                    <h4 className="formheading">Permanent Address</h4>
                                    <div className="form-row">
                                    
                                        <Singleinput 
                                            labelName={'House Number'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter House Number'}
                                            name={'permanantAddHouseNo'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'House Name'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter House Name'}
                                            name={'permanantAddHouseName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                        <Singleinput 
                                            labelName={'Lane Number'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Lane Number'}
                                            name={'permanantAddLaneNumber'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Lane Name'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter Lane Name'}
                                            name={'permanantAddLaneName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Number'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Number'}
                                            name={'permanantAddRoadNo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Name'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Name'}
                                            name={'permanantAddRoadName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Police Station'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddPoliceStn'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Village'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddVillage'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Upozella'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddUpozella'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Post Code'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddPostCode'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'District'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddDistrict'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Nearest Landmark'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'permanantAddNearestLandmark'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                <h4 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                    <span>Nominee Details</span><span className="float-right"><FaAngleDown/></span>
                                    </button>
                                </h4>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                <div className="single-box-wrap mb-5">
                                    <h4 className="formheading">Nominee Details</h4>
                                    <div className="form-row">
                                    
                                        <Singleinput 
                                            labelName={'Nominee Relationship'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            placeholder={'Nominee Relationship'}
                                            name={'nomineeRelationship'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Nominee Percentage'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Nominee Percentage'}
                                            name={'nomineePercentage'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                        <Singleinput 
                                            labelName={'Name'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Name'}
                                            name={'nomineeName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                       <div className={`form-group col-md-6 col-lg-6 col-12 `}>
                                            <label for="photograph"className="mandetory">Photograph</label>
                                            <input type="file" className="form-control" id="inputEmail4" placeholder="" name="nomineePhoto" onChange={nomineeIMGhandler} accept="image/*"/>
                                            <div className={`image-upload-box w-100` } style={{backgroundImage:`url(${stateThird})`,backgroundSize:'cover',backgroundPosition:"center"}}></div>
                                        </div>
                                        <Singleinput 
                                            labelName={'Phone-1'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'nomineePhoneOne'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Phone-2'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'nomineePhoneTwo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                          <Singleinput 
                                            labelName={'House Number'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter House Number'}
                                            name={'nomineeAddHouseNo'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'House Name'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter House Name'}
                                            name={'nomineeAddHouseName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                        <Singleinput 
                                            labelName={'Lane Number'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Lane Number'}
                                            name={'nomineeLaneNumber'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Lane Name'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter Lane Name'}
                                            name={'nomineeAddLaneName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Number'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Number'}
                                            name={'nomineeAddRoadNo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Name'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Name'}
                                            name={'nomineeAddRoadName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Police Station'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'nomineeAddPoliceStn'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Village'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'nomineeAddVillage'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Upozella'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'nomineeAddUpozella'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Post Code'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'nomineeAddPostCode'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'District'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'nomineeAddDistrict'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Nearest Landmark'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'nomineeAddNearestLandmark'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            
                            
                               

                              {/* submit-button */}
                              <div className="form-group text-right">
                                
                                <button   type="submit"  className="primary-button mt-3 border-0"> {loader ?  <span className="px-4"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span> : 'Sign Up'}  </button>
                              </div> 
                          </form>
                       </div>
                   </div>
               </div>
            </div>
        </div>
    )
}

export default Form
