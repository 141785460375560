import React,{useState} from 'react';
import Header from  '../mini-components/Header';
import Singleinput from '../mini-components/Singleinput'
import Footer from  '../mini-components/Footer';
import { NavLink,useHistory } from 'react-router-dom';
import axios from 'axios';
const CreateAdmin = () => {
    const [fileOne, setfileOne] = useState(null)
    const [stateFourth, setstateFourth] = useState(null)
    const history = useHistory()
    const [user, setUser] = useState({
        firstName:'',
        lastName:'',
        phone:'',
        secretCode:'',
        password:''
    });


    
    // get data from the input field
    let name,value;
    const handleInputs =(e)=>{
     name = e.target.name;
     value = e.target.value;
     setUser({...user,[name]:value})

    }


     // sending data to server
     const PostData = async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('fileOne', fileOne);
        formData.append('firstName', user.firstName);
        formData.append('lastName', user.lastName);
        formData.append('email', user.email);
        formData.append('phone', user.phone);
        formData.append('secretCode', user.secretCode);
        formData.append('password', user.password);

        if( !user.firstName ||  !user.lastName || !user.phone || !user.secretCode || !user.password){
            window.alert("Field must not be empty!!")
        }

        axios.post('/create-admin',formData)
        .then(res=>{
            if(res.status === 422){
                window.alert('Invalid Admin Creation!!');
            }
            if(res.status === 409){
                window.alert('This number already Exist!!');
            }
            if(res.status === 401){
                window.alert("Unauthorized!!")
            }
            else{
                window.alert('Successfully Admin Created');
                history.push('/admin')
            }

        })
  
      }

      const imageHandleruserPhoto = (e) => {
        // setNewUser({...user, userPhoto: e.target.files[0]});
        const USER_PHOTO =  e.target.files[0];
        setfileOne(USER_PHOTO);
        const reader = new FileReader();
        reader.onload = ()=>{
            if(reader.readyState === 2){
                setstateFourth(reader.result);
               
            }
    
        }
        reader.readAsDataURL(e.target.files[0])
    }
    return (
        <div>
             <Header 
               linkTwo={<li><NavLink to="/request-list">Verification List</NavLink></li>}
               linkFive={<li><NavLink to="/verifieduser">Verified User List</NavLink></li>}
               linkThree={<li><NavLink to="/admin">Create Project</NavLink></li>}
               linkSix={<li><NavLink to="/create-admin">Create Admin</NavLink></li>}
               linkFour={<li><NavLink to="/admin">Profile</NavLink></li>}
               linkOne={ <li><NavLink to="/admin-logout" onClick={()=>{window.alert("Are you sure to logout..?")}}>Logout</NavLink></li>}
            />
            <div className="form-wrapper section-padding">
               <div className="container">
                   <div className="row">
                       <div className="col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                         <h2 className="text-center">Create Admin</h2>
                         <form method="POST">
                               <div className="single-box-wrap mb-5">
                                <div className="form-row">
                                    <Singleinput 
                                        labelName={'First Name'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'text'}
                                        placeholder={'Enter first name'}
                                        name={'firstName'}
                                        mandetory={'mandetory'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                     <Singleinput 
                                        labelName={'Last Name'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'text'}
                                        placeholder={'Enter last name'}
                                        name={'lastName'}
                                        mandetory={'mandetory'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                     <Singleinput 
                                        labelName={'Email'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'email'}
                                        placeholder={'Enter last name'}
                                        name={'email'}
                                        mandetory={'mandetory'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                     <Singleinput 
                                        labelName={'Phone'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'text'}
                                        placeholder={'Enter last name'}
                                        name={'phone'}
                                        mandetory={'mandetory'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                    <Singleinput 
                                        labelName={'Secret Code'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'text'}
                                        placeholder={'Enter last name'}
                                        name={'secretCode'}
                                        mandetory={'mandetory'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                      <Singleinput 
                                        labelName={'Password'} 
                                        col={'col-md-12 col-lg-12 col-12 '} 
                                        type={'password'}
                                        placeholder={'Enter password'}
                                        name={'password'}
                                        mandetory={'mandetory'}
                                        func={handleInputs}
                                        value={user.name}
                                    />
                                    <div className={`form-group col-md-12 col-lg-12 col-12 `}>
                                            <label for="refId"className="mandetory">User Photo</label>
                                            <input type="file" className="form-control" id="inputEmail4" placeholder="" name="userPhoto" accept="image/*" onChange={imageHandleruserPhoto}/>
                                            <div className={`image-upload-box w-100` } style={{backgroundImage:`url(${stateFourth})`,backgroundSize:'cover',backgroundPosition:"center"}}></div>
                                        </div>
                                  </div>
                               </div>
                            <div className="form-group text-right">
                             <button onClick={PostData} type="submit" className="primary-button mt-1 border-0">Sign Up</button>
                            </div>
                            </form>
                       </div>
                   </div>
               </div>
            </div>
            <Footer/>
        </div>
    )
}

export default CreateAdmin
