import React, { createContext,useReducer } from 'react';
import './App.css'
import Home from '../src/components/pages/Home';
import Signup from '../src/components/pages/Signup';
import Signin from '../src/components/pages/Signin';
import Dashboard from '../src/components/pages/Dashboard';
import Verify from '../src/components/pages/Verify';
import Verifyuser from '../src/components/pages/Verify-user';
import Admin from '../src/components/pages/Admin';
import Requestlist from '../src/components/pages/Request-list';
import Adminlogin from '../src/components/pages/admin-login';
import Verifyphone from '../src/components/pages/Verifyphone';
import Personaldataupdate from '../src/components/pages/Personaldataupdate';
import Permanantdataupdate from '../src/components/pages/permanantdataupdate';
import Presentdataupdate from '../src/components/pages/presentdataupdate';
import Nomineedataupdate from '../src/components/pages/Nomineedataupdate';
import Singlerequestview from '../src/components/pages/Single-request-view';
import Project from '../src/components/pages/Project';
import Verifieduser from '../src/components/pages/Verifieduser';
import Createadmin from '../src/components/pages/Create-admin';
import Adminlogout from '../src/components/pages/Admin-logout';
import Codeverify from '../src/components/pages/Codeverify';
import Sendsms from '../src/components/pages/Sms';
import Logout from '../src/components/pages/Logout';
import Userverifymedium from '../src/components/pages/Userverifimedium';
import Userprofile from '../src/components/pages/Userprofile';
import Imageview from '../src/components/pages/Imageview'
import Addnominee from '../src/components/pages/Addnominee';
import Deleteuser from '../src/components/pages/delete-user';
import{Route, Switch} from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import '../node_modules/owl.carousel/dist/assets/owl.theme.default.css';


function App() {
  
  return (
    <Switch>
      <Route exact path='/'>
        <Home/>
      </Route>
      <Route exact path='/signup'>
        <Signup/>
      </Route>
      <Route exact path='/signin'>
        <Signin/>
      </Route>
      <Route exact path='/dashboard'>
        <Dashboard/>
      </Route>
      <Route exact path='/verify'>
        <Verify/>
      </Route>
      <Route exact path='/verify-user/:key'>
        <Verifyuser/>
      </Route>
      <Route exact path='/personaldataupdate'>
        <Personaldataupdate/>
      </Route>
      <Route exact path='/presentdataupdate'>
        <Presentdataupdate/>
      </Route>
      <Route exact path='/permanantdataupdate'>
        <Permanantdataupdate/>
      </Route>
      <Route exact path='/nomineedataupdate'>
        <Nomineedataupdate/>
      </Route>
      <Route exact path='/verifyphone'>
        <Verifyphone/>
      </Route>
      <Route exact path='/admin'>
        <Admin/>
      </Route>
      <Route exact path='/admin-login'>
        <Adminlogin/>
      </Route>
      <Route exact path='/request-list'>
        <Requestlist/>
      </Route>
      <Route exact path='/single-request-view/:key'>
        <Singlerequestview/>
      </Route>
      <Route exact path='/verifieduser'>
        <Verifieduser/>
      </Route>
      <Route exact path='/user-verifymedium/:key'>
        <Userverifymedium/>
      </Route>
      <Route exact path='/create-admin'>
        <Createadmin/>
      </Route>
      <Route exact path='/project'>
        <Project/>
      </Route>
      <Route exact path='/admin-logout'>
        <Adminlogout/>
      </Route>
      <Route exact path='/logout'>
        <Logout/>
      </Route>
      <Route exact path='/delete-user/:key'>
        <Deleteuser/>
      </Route>
      <Route exact path='/codeverify'>
        <Codeverify/>
      </Route>
      <Route exact path='/user-profile'>
        <Userprofile/>
      </Route>
      <Route exact path='/sendsms/:refId/:perPhoneOne'>
        <Sendsms/>
      </Route>
      <Route exact path='/addnominee'>
        <Addnominee/>
      </Route>
      <Route exact path='/imageview'>
        <Imageview/>
      </Route>
    
    {/* <Route>
      <Errorpage />
    </Route> */}
  </Switch>
  )
}

export default App;
