import React,{useState,useEffect} from 'react';
import Header from  '../mini-components/Header';
import Footer from  '../mini-components/Footer';
import { NavLink,useHistory } from 'react-router-dom';
import ReqPhoto from './../../images/req.jpg'
import { BiUserCircle } from "react-icons/bi";
import { VscLocation } from "react-icons/vsc";
import { AiOutlineMail } from "react-icons/ai";


const Verifieduser = () => {

    const history =  useHistory();
    const [ userData, setuserdata ] = useState([]);
    // const [ presentAddress, setpresentAddress ] = useState({});
    
      const callAboutPage = async()=>{
          try {
          const res = await  fetch('/api/verifieduser',{
              method:'GET',
              headers:{
                  Accept:'application/json',
                  'Content-Type':'application/json'
              },
              credentials:"include"
          });
          const data = await res.json();
        //   const PESONAL_INFO = data[0];
        //   const PRESENT_ADDRESS = data[1];
        setuserdata(data);
        console.log(data)
        //   setpresentAddress(PRESENT_ADDRESS);
          if(!res.status === 200 ){
              const error = new Error(res.error);
              throw error;
          }
              
          } catch (error) {
              console.log(error)
              history.push('/admin')
          }
      }
  
  
     useEffect(() => {
      //first load when this page is loaded
        callAboutPage()
         
     }, [])
    return (
        <div>
            <Header 

                 linkTwo={<li><NavLink to="/request-list">Verification List</NavLink></li>}
                 linkFive={<li><NavLink to="/verifieduser">Verified User List</NavLink></li>}
                 linkThree={<li><NavLink to="/admin">Create Project</NavLink></li>}
                 linkSix={<li><NavLink to="/create-admin">Create Admin</NavLink></li>}
                 linkFour={<li><NavLink to="/admin">Profile</NavLink></li>}
                 linkOne={ <li><NavLink to="/admin-logout" onClick={()=>{window.alert("Are you sure to logout..?")}}>Logout</NavLink></li>}
            />
             <div className="new-user-req-wrap container">
                 <h4 className="p-3 border-bottom heading">Verified User: <strong>{userData.length}</strong></h4>
                 
                 { userData.map((data,index) =>
                     
                <div key={index} className="single-req-wrap border mb-2">
                     <div className="row">
                         <div className="col-md-6 col-lg-6 col-12 d-flex align-items-center">
                            <div className="req-img w-25 ">
                                <img className="img-fluid" src={ReqPhoto} alt="img" />
                            </div>
                            <div className="req-content-left">
                                <table>
                                    <tr><td><BiUserCircle/></td><td><strong>{data.firstName}</strong></td></tr>
                                    <tr><td><AiOutlineMail/></td><td>{data.email}</td></tr>
                                    <tr><td><BiUserCircle/></td><td>{data.perPhoneOne}</td></tr>
                                </table>
                            </div>
                         </div>
                         <div className="col-md-6 col-lg-6 col-12 d-flex align-items-end justify-content-end flex-column">
                          <span className="mr-2" >{data.createdDate} <span className="d-block font-weight-bold">{data.createdAt}</span></span>
                            <div className="req-content-left mr-2 mt-2">
                               <ul className="d-flex">
                                   <li><NavLink  to={`/single-request-view/${data.perPhoneOne}`}>View</NavLink></li>
                                   <li><NavLink  to={`/delete-user/${data.perPhoneOne}`}  onClick={()=>{window.alert("Are you sure to delete this user???" )}}>Delete</NavLink></li>
                               </ul>
                            </div>
                         </div>
                     </div>

                   </div>
                   
                      
                 )}
                 
             </div>
            <Footer/>
        </div>
    )
}

export default Verifieduser;
