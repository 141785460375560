import React,{useState} from 'react';
import { useHistory,NavLink } from 'react-router-dom';
import Header from '../mini-components/Header';
import Footer from '../mini-components/Footer';
import Singleinput from '../mini-components/Singleinput';
import { FaAngleDown } from "react-icons/fa";
import Verify from './Verify';
import axios from 'axios';

const Form = () => {
    const url = window.location.href;
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [msg, setMsg] = useState()

    const [fileOne, setfileOne] = useState(null)
// axios---------------------------------------------
const [user, setNewUser] = useState(
    {
        secondNomineeAddHouseName:'',
        secondNomineeLaneNumber:'',
        secondNomineeAddRoadNo:'',
        secondNomineePhoneOne:'',
        secondNomineePhoneTwo:'',
        secondNomineeAddPoliceStn:'',
        secondNomineeAddVillage:'',
        secondNomineeAddUpozella:'',
        secondNomineeAddPostCode:'',
        secondNomineeAddDistrict:'',
        secondNomineeRelationship:'',
        secondNomineePercentage:'',
        secondNomineeName:'',
    }
);
const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append('fileOne', fileOne);
    formData.append('secondNomineeAddHouseName', user.secondNomineeAddHouseName);
    formData.append('secondNomineeLaneNumber', user.secondNomineeLaneNumber);
    formData.append('secondNomineeAddRoadNo', user.secondNomineeAddRoadNo);
    formData.append('secondNomineePhoneOne', user.secondNomineePhoneOne);
    formData.append('secondNomineePhoneTwo', user.secondNomineePhoneOne);
    formData.append('secondNomineeAddPoliceStn', user.secondNomineeAddPoliceStn);
    formData.append('secondNomineeAddUpozella', user.secondNomineeAddUpozella);
    formData.append('secondNomineeAddPostCode', user.secondNomineeAddPostCode);
    formData.append('secondNomineeAddDistrict', user.secondNomineeAddDistrict);
    formData.append('secondNomineeRelationship', user.secondNomineeRelationship);
    formData.append('secondNomineePercentage', user.secondNomineePercentage);
    formData.append('secondNomineeName', user.secondNomineeName);
    // validation----------------------
    // if(user.refereeId === ""){
    //     setMsg(<div class="alert alert-danger" role="alert"><strong>RefereeId must not be empty!!</strong></div>)
    //     console.log("referee id is empty!!")
    // }
    // if(!fileOne || !fileTwo || !fileThree || !fileFour){
    //     setMsg(<div class="alert alert-danger" role="alert"><strong>All Photograph Field Required!!!</strong></div>)
    //     console.log("referee id is empty!!")
    // }
     
  axios.post(url, formData)
         .then(res => {
            console.log(res);
            if(res.status === 404){
                setMsg(<div class="alert alert-danger" role="alert"><strong>Invalid Creation!!</strong></div>)
            }
            if(res.status === 422){
                setMsg(<div class="alert alert-danger" role="alert"><strong>Invalid Registration!!</strong></div>)
               
            }
            else{
                setMsg(<div class="alert alert-success" role="alert"><strong>Successfully Created.</strong></div>)
                history.push('/user-profile')
                // window.alert('code has been sent');
                console.log("formData:",user)
                // history.push(`/sendsms?refId=${user.refereeId}&perPhoneOne=${user.perPhoneOne}`)
            }
         })
         .catch(err => {
            console.log(err);
         });
};
const handleInputs = (e) => {
    setNewUser({...user, [e.target.name]: e.target.value});
}

//  IMAGE PREVIEW + Upload=========================
const [stateThird, setstateThird] = useState(null)
const nomineeIMGhandler = (e) => {
    const NOMINEE_PHOTO =  e.target.files[0];
    setfileOne(NOMINEE_PHOTO);
    // setNewUser({...user, nomineePhoto: e.target.files[0]});
    const reader = new FileReader();
    reader.onload = ()=>{
        if(reader.readyState === 2){
            setstateThird(reader.result);
        }
    }
    reader.readAsDataURL(e.target.files[0])
}
   
const loaderDis = setTimeout(() => {
    setLoader(false);
  }, 3000);

    return (
        <div>
             <Header 
               linkOne={<li><NavLink to="/logout">Logout</NavLink></li> }
               linkTwo={<li><NavLink to="/user-profile">Profile</NavLink></li>}
               linkThree={<li><NavLink to="/project">project</NavLink></li>}
               linkFour={<li><NavLink to="/dashboard">Dashboard</NavLink></li>}
             />
            <div className="form-wrapper section-padding">
               <div className="container">
                   <div className="row">
                       <div className="col-md-12 col-lg-12">
                           {msg}
                         <h2 className="text-center">Add New Nominee</h2>
                         <form onSubmit={handleSubmit}>
                         <div className="accordion custom-accordion" id="accordionExample" >
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                <h4 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                    <span>Nominee Details</span><span className="float-right"><FaAngleDown/></span>
                                    </button>
                                </h4>
                                </div>
                                <div id="collapseFour" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                <div className="single-box-wrap mb-5">
                                    <h4 className="formheading">Nominee Details</h4>
                                    <div className="form-row">
                                    
                                        <Singleinput 
                                            labelName={'Nominee Relationship'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            placeholder={'Nominee Relationship'}
                                            name={'secondNomineeRelationship'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Nominee Percentage'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Nominee Percentage'}
                                            name={'secondNomineePercentage'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                        <Singleinput 
                                            labelName={'Name'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Name'}
                                            name={'secondNomineeName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                       <div className={`form-group col-md-6 col-lg-6 col-12 `}>
                                            <label for="photograph"className="mandetory">Photograph</label>
                                            <input type="file" className="form-control" id="inputEmail4" placeholder="" name="nomineePhoto" onChange={nomineeIMGhandler} accept="image/*"/>
                                            <div className={`image-upload-box w-100` } style={{backgroundImage:`url(${stateThird})`,backgroundSize:'cover',backgroundPosition:"center"}}></div>
                                        </div>
                                        <Singleinput 
                                            labelName={'Phone-1'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineePhoneOne'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Phone-2'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineePhoneTwo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                          <Singleinput 
                                            labelName={'House Number'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter House Number'}
                                            name={'secondNomineeAddHouseNo'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'House Name'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter House Name'}
                                            name={'secondNomineeAddHouseName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                        <Singleinput 
                                            labelName={'Lane Number'} 
                                            col={'col-md-4 col-lg-4 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Lane Number'}
                                            name={'secondNomineeLaneNumber'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Lane Name'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            placeholder={'Enter Lane Name'}
                                            name={'secondNomineeAddLaneName'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Number'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Number'}
                                            name={'secondNomineeAddRoadNo'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Road Name'} 
                                            col={'col-md-6 col-lg-6 col-12'} 
                                            type={'text'}
                                            placeholder={'Enter Road Name'}
                                            name={'secondNomineeAddRoadName'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Police Station'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineeAddPoliceStn'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Village'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineeAddVillage'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Upozella'} 
                                            col={'col-md-6 col-lg-6 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineeAddUpozella'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Post Code'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineeAddPostCode'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'District'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineeAddDistrict'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />
                                        <Singleinput 
                                            labelName={'Nearest Landmark'} 
                                            col={'col-md-4 col-lg-4 col-12 '} 
                                            type={'text'}
                                            name={'secondNomineeAddNearestLandmark'}
                                            mandetory={'mandetory'}
                                            func={handleInputs}
                                            value={user.name}
                                        />

                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            
                              {/* submit-button */}
                              <div className="form-group text-right">
                                
                                <button   type="submit"  className="primary-button mt-3 border-0"> {loader ?  <span className="px-4"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span> : 'Sign Up'}  </button>
                              </div> 
                          </form>
                       </div>
                   </div>
               </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Form
