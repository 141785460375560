import React,{useState,useEffect} from 'react';
import Header from  '../mini-components/Header';
import Singleproject from  '../mini-components/Singleproject';
import Footer from  '../mini-components/Footer';
import { NavLink ,useHistory} from 'react-router-dom';
import Car from '../../images/project/car.jpg';
import Chemical from '../../images/project/chemical.jpg';
import Food from '../../images/project/food.jpg';
import Apparel from '../../images/project/apparel.jpg';

const Project = () => {

    const url = '/api/user-profile'
    const history =  useHistory();
    const [img ,setImg] = useState({});
    const [NIDfront ,setNIDfront] = useState({});
    const [nomineeOnePhoto ,setNomineeimg] = useState({});
    const [nomineeTwoPhoto ,setNomineetwoimg] = useState({});
    const [NIDback ,setNIDback] = useState({});
    const [ personalInfo, setpersonalInfo ] = useState({});
    const [ presentAddress, setpresentAddress ] = useState({});
    const [ permanantAddress, setpermanantAddress ] = useState({});
    const [ nomineeOne, setnomineeOne ] = useState({});
    const [ nomineeTwo, setnomineeTwo ] = useState({});
  
    
    const [isPending, setIspending] =  useState(true);
    useEffect(() => {
     const abortCont =  new AbortController()
      setTimeout(()=>{
          fetch(url,{signal:abortCont.signal})
          .then(res=>{
              if(!res.status === 200){
                 const error = new Error(res.error);
                 throw error;
              }
              return res.json();
          })
          .then(resdata =>{
              const data = resdata;
              console.log("dashboard data:",resdata)
  
              const PESONAL_INFO = data[0];
              const PESONAL_INFO_OBJ = PESONAL_INFO[0]
  
              const PRESENT_ADDRESS = data[1];
              const PRESENT_ADDRESS_OBJ = PRESENT_ADDRESS[0];
  
              const PERMANANT_ADDRESS = data[2];
              const PERMANANT_ADDRESS_OBJ = PERMANANT_ADDRESS[0];

              const NOMINEE_ONE = data[3];
              const NOMINEE_ONE_OBJ = NOMINEE_ONE[0];

              const NOMINEE_TWO = data[4];
              const NOMINEE_TWO_OBJ =NOMINEE_TWO[0];
  
             const photodata = PESONAL_INFO_OBJ.userPhoto;
             const photodataNIDF = PESONAL_INFO_OBJ.NIDfront;
             const photodataNIDB = PESONAL_INFO_OBJ.NIDback;
             const nomineeOnePhoto = NOMINEE_ONE_OBJ.nomineePhoto;
             const nomineeTwoPhoto = NOMINEE_TWO_OBJ.nomineePhoto;
             setNomineeimg(nomineeOnePhoto)
             setNomineetwoimg(nomineeTwoPhoto)
             setImg(photodata)
             setNIDfront(photodataNIDF)
             setNIDback(photodataNIDB)
             setpersonalInfo(PESONAL_INFO_OBJ);
             setpresentAddress(PRESENT_ADDRESS_OBJ);
             setpermanantAddress(PERMANANT_ADDRESS_OBJ);
             setnomineeOne(NOMINEE_ONE_OBJ);
             setnomineeTwo(NOMINEE_TWO_OBJ);
             setIspending(false)
          })
          .catch(err=>{
              if(err.name === 'AbortError'){
                  console.log("fetch aborted!!")
              }
              else{
                 setIspending(false); 
              }
               
          })
      },1000);
    
  
     return () => abortCont.abort();
        
    }, [])
    return (
        <div>
            <Header 
               linkOne={<li><NavLink to="/logout">Logout</NavLink></li> }
               linkTwo={<li><NavLink to="/user-profile">Profile</NavLink></li>}
               linkThree={<li><NavLink to="/project">project</NavLink></li>}
               linkFour={<li><NavLink to="/dashboard">Dashboard</NavLink></li>}
             />
              
             <div className="container">
                 <div className="row">
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Car} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Chemical} title={'002-DEF  Chemicals'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Food} title={'003-GHI  Foods'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Apparel} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Car} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Chemical} title={'002-DEF  Chemicals'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Food} title={'003-GHI  Foods'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-sm-6 col-12 my-3">
                        <Singleproject img={Apparel} title={'001-ABC  Apparels'} details={'Some quick example text to build on the card title and make up the bulk of the cards content.'}/>
                    </div>
                 </div>
             </div>
    
            <Footer/>
        </div>
    )
}

export default Project;
