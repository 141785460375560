import React,{useState,useEffect} from 'react';
import Header from  '../mini-components/Header';
import Footer from  '../mini-components/Footer';
import { NavLink,useHistory,useParams } from 'react-router-dom';
// import ReqPhoto from './../../images/req.jpg'
// import { BiUserCircle } from "react-icons/bi";
// import { VscLocation } from "react-icons/vsc";
// import { AiOutlineMail } from "react-icons/ai";
// import Img from './../mini-components/Img'


const Requestview = () => {
    const{ key }  = useParams();
    const history =  useHistory();
    const [img ,setImg] = useState({});
    const [NIDfront ,setNIDfront] = useState({});
    const [NIDback ,setNIDback] = useState({});
    const [nomineephoto ,setnomineephoto] = useState({});
    const [ userData, setuserdata ] = useState({});
    const [ presentAddress, setpresentAddress ] = useState({});
    const [ permanantAddress, setpermanantAddress ] = useState({});
    const [ nomineeInfo, setnomineeInfo ] = useState({});
      const callAboutPage = async()=>{
          const url = window.location.href
          try {
          const res = await  fetch(`/api/single-request-view/${key}`,{
              method:'GET',
              headers:{
                  Accept:'application/json',
                  'Content-Type':'application/json'
              },
              credentials:"include"
          });
          const data = await res.json();
          console.log(data)
          const PERSONAL_INFO = data[0];
          console.log("personal data:",PERSONAL_INFO[0])
          const PRESENT_ADDRESS = data[1];
          const PERMANANT_ADDRESS = data[2];
          const NOMINEE_INFO = data[3];
          setuserdata(PERSONAL_INFO[0]);
          setpresentAddress(PRESENT_ADDRESS[0]);
          setpermanantAddress(PERMANANT_ADDRESS[0]);
          setnomineeInfo(NOMINEE_INFO[0]);

          const photodata =  PERSONAL_INFO.userPhoto;
          const photodataNIDF =  PERSONAL_INFO.NIDfront;
          const photodataNIDB =  PERSONAL_INFO.NIDback;
          const photoNominee = NOMINEE_INFO.nomineePhoto;
          setImg(photodata)
          setNIDfront(photodataNIDF)
          setNIDback(photodataNIDB)
          setNIDback(photodataNIDB)
          setnomineephoto(photoNominee)
          if(!res.status === 200 ){
              const error = new Error(res.error);
              throw error;
          }
              
          } catch (error) {
              console.log(error)
            //   history.push('/admin')
          }
      }
  
  
     useEffect(() => {
      //first load when this page is loaded
        callAboutPage()
         
     }, [])
    return (
        <div>
            <Header 
               linkTwo={<li><NavLink to="/request-list">Verification List</NavLink></li>}
               linkFive={<li><NavLink to="/verifieduser">Verified User List</NavLink></li>}
               linkThree={<li><NavLink to="/admin">Create Project</NavLink></li>}
               linkSix={<li><NavLink to="/create-admin">Create Admin</NavLink></li>}
               linkFour={<li><NavLink to="/admin">Profile</NavLink></li>}
               linkOne={ <li><NavLink to="/admin-logout" onClick={()=>{window.alert("Are you sure to logout..?")}}>Logout</NavLink></li>}
            />
             <div className="new-user-req-wrap container">
                <h4 className="pb-3 border-bottom heading">Request User Profile:</h4>  
                <div className="single-req-wrap single-view border mb-2">
                     <div className="row">
                         <div className="col-md-12 col-lg-12 col-12">
                            <div className="req-content-left">
                            <h4 className="p-3">Personal Information</h4>
                            <table class="table p-3 mb-3 table-responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">User ID</th>
                                        <th scope="col">Date of Birth</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">NID NO</th>
                                        <th scope="col">Referee ID</th>
                                        <th scope="col">User Photo</th>
                                        <th scope="col">NID Front</th>
                                        <th scope="col">NID Back</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>{userData.firstName}</td>
                                        <td>{userData.lastName}</td>
                                        <td>{userData.userId}</td>
                                        <td>{userData.dateOfBirth}</td>
                                        <td>{userData.perPhoneOne}</td>
                                        <td>{userData.refereeId}</td>
                                        <td>{userData.userId}</td>
                                        <td>
                                        {/* <img width={150}  src={process.env.PUBLIC_URL + `images/${userData.userPhoto}`}  alt="img" /> */}
                                        <img src={`data:image/png;base64,${userData.userPhoto}`} alt="img"  width={150}/>
                                        
                                      </td>
                                        <td>
                                        {/* <img width={150}  src={process.env.PUBLIC_URL + `images/${userData.NIDfront}`}  alt="img" /> */}
                                        <img src={`data:image/png;base64,${userData.NIDfront}`} alt="img"  width={150}/>
                                        
                                      </td>
                                      <td>
                                        {/* <img width={150}  src={process.env.PUBLIC_URL + `images/${userData.NIDback}`}  alt="img" /> */}
                                        <img src={`data:image/png;base64,${userData.NIDback}`} alt="img"  width={150}/>
                                        
                                      </td>
                                    </tr>
                                </tbody>
                                </table>
                                <h4 className="p-3">Present Address</h4>
                                <table class="table p-3 mb-3 table-responsive">
                                    
                                    <thead>
                                        <tr>
                                        <th scope="col">presentAddHouseNo</th>
                                        <th scope="col">presentAddHouseName</th>
                                        <th scope="col">presentLaneNumber</th>
                                        <th scope="col">presentAddRoadNo</th>
                                        <th scope="col">presentAddPoliceStn</th>
                                        <th scope="col">presentAddVillage</th>
                                        <th scope="col">presentAddUpozella</th>
                                        <th scope="col">presentAddPostCode</th>
                                        <th scope="col">presentAddDistrict</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        
                                        <td>{presentAddress.presentAddHouseNo}</td>
                                        <td>{presentAddress.presentAddHouseName}</td>
                                        <td>{presentAddress.presentLaneNumber}</td>
                                        <td>{presentAddress.presentAddRoadNo}</td>
                                        <td>{presentAddress.presentAddPoliceStn}</td>
                                        <td>{presentAddress.presentAddVillage}</td>
                                        <td>{presentAddress.presentAddUpozella}</td>
                                        <td>{presentAddress.presentAddPostCode}</td>
                                        <td>{presentAddress.presentAddDistrict}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4 className="p-3">Permanant Address</h4>
                                <table class="table p-3 mb-3 table-responsive">
                                    
                                    <thead>
                                        <tr>
                                        <th scope="col">permanantAddHouseNo</th>
                                        <th scope="col">permanantAddHouseName</th>
                                        <th scope="col">permanantLaneNumber</th>
                                        <th scope="col">permanantAddRoadNo</th>
                                        <th scope="col">permanantAddPoliceStn</th>
                                        <th scope="col">permanantAddVillage</th>
                                        <th scope="col">permanantAddUpozella</th>
                                        <th scope="col">permanantAddPostCode</th>
                                        <th scope="col">permanantAddDistrict</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            
                                            <td>{permanantAddress.permanantAddHouseNo}</td>
                                            <td>{permanantAddress.permanantAddHouseName}</td>
                                            <td>{permanantAddress.permanantLaneNumber}</td>
                                            <td>{permanantAddress.permanantAddRoadNo}</td>
                                            <td>{permanantAddress.permanantAddPoliceStn}</td>
                                            <td>{permanantAddress.permanantAddPoliceStn}</td>
                                            <td>{permanantAddress.permanantAddUpozella}</td>
                                            <td>{permanantAddress.permanantAddPostCode}</td>
                                            <td>{permanantAddress.permanantAddDistrict}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4 className="p-3">Nominees Info</h4>
                                <table class="table p-3 mb-3 table-responsive">
                                    
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Relationship</th>
                                            <th scope="col">Percentage</th>
                                            <th scope="col">Photograph</th>
                                            <th scope="col">nomineeAddHouseNo</th>
                                            <th scope="col">nomineeAddHouseName</th>
                                            <th scope="col">nomineeLaneNumber</th>
                                            <th scope="col">nomineeAddRoadNo</th>
                                            <th scope="col">nomineeAddPoliceStn</th>
                                            <th scope="col">nomineeAddVillage</th>
                                            <th scope="col">nomineeAddUpozella</th>
                                            <th scope="col">nomineeAddPostCode</th>
                                            <th scope="col">nomineeAddDistrict</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            
                                            <td>{nomineeInfo.nomineeName}</td>
                                            <td>{nomineeInfo.nomineePhone}</td>
                                            <td>{nomineeInfo.nomineeRelationship}</td>
                                            <td>{nomineeInfo.nomineePercentage}</td>
                                            <td> <img src={`data:image/png;base64,${nomineeInfo.nomineePhoto}`} alt="img"  width={150}/></td>
                                            <td>{nomineeInfo.nomineeAddHouseNo}</td>
                                            <td>{nomineeInfo.nomineeAddHouseName}</td>
                                            <td>{nomineeInfo.nomineeLaneNumber}</td>
                                            <td>{nomineeInfo.nomineeAddRoadNo}</td>
                                            <td>{nomineeInfo.nomineeAddPoliceStn}</td>
                                            <td>{nomineeInfo.nomineeAddUpozella}</td>
                                            <td>{nomineeInfo.nomineeAddPostCode}</td>
                                            <td>{nomineeInfo.nomineeAddDistrict}</td>
                                            <td>{nomineeInfo.nomineeAddDistrict}</td>
                                            <td>{nomineeInfo.nomineeAddDistrict}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                         </div>
                         <div className="col-md-12 col-lg-12 col-12 ">
                          
                            <div className="req-content-left mr-2 float-right mt-2">
                               <ul className="d-flex">
                                   <li><NavLink  to={`/verify-user/${userData.perPhoneOne}`}>Verify</NavLink></li>
                                   <li><NavLink  to={`/delete-user/${userData.perPhoneOne}`} onClick={()=>{window.alert("Are you sure to delete this user???" )}}>Delete</NavLink></li>
                               </ul>
                            </div>
                         </div>
                     </div>

                   </div>
                   
                      
             
                 
             </div>
            <Footer/>
        </div>
    )
}

export default Requestview;
