import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer>
                <p className="text-center text-light p-3" style={{backgroundColor:'#024649'}}>All Rights Reserved © crowdcarnival 2021</p>
            </footer>
        </div>
    )
}

export default Footer
