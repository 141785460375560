import React,{useEffect, useState} from 'react';
import Header from  '../mini-components/Header';
import Singleinput from '../mini-components/Singleinput'
import Footer from  '../mini-components/Footer';
// import Verify from '../mini-components/Verify';
import {useHistory} from 'react-router-dom';

const Adminlogout = () => {
    const url = window.location.href;
    const history =  useHistory();
    const [ userData, setUserData ] = useState();

    // Fetching  verify page
   
    const callAboutPage = async()=>{
        try {
        const res = await  fetch('/admin-logout',{
            method:'GET',
            headers:{
                Accept:'application/json',
                'Content-Type':'application/json',
                
            },
            credentials:"include"
            
        });
        const data = await res.json();
        if(!res.status === 200 ){
            const error = new Error(res.error);
            throw error;
        }
        if(res.status === 200){
            history.push('/admin-login');
        }
        } catch (error) {
            console.log("this is error part:",error)
            // history.push('/admin-login')
        }
    }
    
   useEffect(() => {
    //first load when this page is loaded
      callAboutPage();
       
   }, [])


    return (
        <div>
            <Header/>
            <div className="form-wrapper section-padding">
               <div className="container">
                   <div className="row">
                       <div className="col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                         <h2 className="text-center">Logout</h2>
                         
                       </div>
                   </div>
               </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Adminlogout;
