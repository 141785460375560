import React from 'react';
import Header from  '../mini-components/Header';
import Footer from  '../mini-components/Footer';
import Userprofile from  '../mini-components/Userprofile-admin';
import { NavLink } from 'react-router-dom';

const Admin = () => {
    const link = 'admin'

    return (
        <div>
            <Header 
               homelink={link}
               
               linkTwo={<li><NavLink to="/request-list">Verification List</NavLink></li>}
               linkFive={<li><NavLink to="/verifieduser">Verified User List</NavLink></li>}
               linkThree={<li><NavLink to="/admin">Create Project</NavLink></li>}
               linkSix={<li><NavLink to="/create-admin">Create Admin</NavLink></li>}
               linkFour={<li><NavLink to="/admin">Profile</NavLink></li>}
               linkOne={ <li><NavLink to="/admin-logout" onClick={()=>{window.alert("Are you sure to logout..?")}}>Logout</NavLink></li>}
             />
            <Userprofile/>
            <Footer/>
        </div>
    )
}

export default Admin;
